import React from 'react'
import Link from 'gatsby-link'
import FormReserve from '../components/ReservePage/form'
import {reserve_title, reserve_subtitle} from '../components/strings'
import Layout from '../components/layout'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.min.css";
const ReservePage = () => (
    <Layout>
    <ScrollAnimation animateIn='fadeIn' animateOut='fadeOut' className="section reserve">
  <Link to="/" className="close-btn"><FontAwesomeIcon icon="times"/></Link>
         <div className="container">
              <div className="title-box">
                  <h2 className="">{reserve_title}</h2>
                  <p className="">{reserve_subtitle}</p>   
              </div>
          <FormReserve/>
      </div>
   </ScrollAnimation>
   </Layout>
)

export default ReservePage
